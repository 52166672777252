/**
 * @generated SignedSource<<f85202a7f7705318b8bad1ebed1e6c08>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ElementTypeV2 = "randomMultipleChoice" | "file" | "multipleChoice" | "podcast" | "text" | "video" | "order" | "clozeText" | "%future added value";
export type StructureType = "root" | "child" | "%future added value";
export type TypeDefinitionType = "content" | "branch" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TreeContainer_TreeNodeFragment$data = {
  readonly id: string;
  readonly hasBeenPublishedOnce: boolean;
  readonly parentId: string | null;
  readonly structureDefinition: {
    readonly coordinates: {
      readonly parentRef: string | null;
    };
    readonly definitionType: StructureType;
    readonly title: string;
  };
  readonly typeDefinition: {
    readonly definitionType: TypeDefinitionType;
    readonly childRefs?: ReadonlyArray<string>;
    readonly elements?: ReadonlyArray<{
      readonly id: string;
      readonly elementType: ElementTypeV2;
      readonly " $fragmentSpreads": FragmentRefs<"ElementNode_ElementV2Fragment">;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"Node_TreeNodeFragment" | "DeleteChildNodeButton_TreeNodeFragment">;
  readonly " $fragmentType": "TreeContainer_TreeNodeFragment";
};
export type TreeContainer_TreeNodeFragment = TreeContainer_TreeNodeFragment$data;
export type TreeContainer_TreeNodeFragment$key = {
  readonly " $data"?: TreeContainer_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TreeContainer_TreeNodeFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TreeContainer_TreeNodeFragment"
};

(node as any).hash = "27c35697af4051b9459a34c5ec800a24";

export default node;
