/**
 * @generated SignedSource<<a46fff28c2a8cdc602747c11550808d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type VisibilityTreeConfigType = "VisibilityTree_OnlyFachrichtungen" | "VisibilityTree_OnlyIfTreeStateOrAdmin" | "VisibilityTree_OnlySpecificPermissions" | "VisibilityTree_HideForFachberater" | "VisibilityTree_OnlyBasicSeminar" | "VisibilityTree_OnlyFachberater" | "VisibilityTree_AfterDateTime" | "VisibilityTree_Hide" | "VisibilityTree_NotAfterDateTime" | "VisibilityTree_OnlyAdmins" | "VisibilityTree_OnlyIfTreeState" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RootNodesTable_TreeNodeFragment$data = {
  readonly structureDefinition: {
    readonly extension?: {
      readonly configConsequences?: {
        readonly rewardExpired: boolean;
      };
    };
    readonly visibilityConfigs?: ReadonlyArray<{
      readonly configType: VisibilityTreeConfigType;
      readonly id?: string;
      readonly dateTime?: string;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ActionColumn_TreeNodeFragment" | "ConfigurationBadgesColumn_TreeNodeFragment" | "ContentColumn_TreeNodeFragment" | "IsRewardedToColumn_TreeNodeFragment" | "ReleasedAtColumn_TreeNodeFragment" | "TagsColumn_TreeNodeFragment" | "TitleColumn_TreeNodeFragment">;
  readonly " $fragmentType": "RootNodesTable_TreeNodeFragment";
};
export type RootNodesTable_TreeNodeFragment = RootNodesTable_TreeNodeFragment$data;
export type RootNodesTable_TreeNodeFragment$key = {
  readonly " $data"?: RootNodesTable_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RootNodesTable_TreeNodeFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "RootNodesTable_TreeNodeFragment"
};

(node as any).hash = "0ff2e972d97f9192bac294768779e7cb";

export default node;
