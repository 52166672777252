/**
 * @generated SignedSource<<64317fe90150ad7f662aa6d63fcc9f22>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ElementTypeV2 = "randomMultipleChoice" | "file" | "multipleChoice" | "podcast" | "text" | "video" | "order" | "clozeText" | "%future added value";
export type CreateElementsPoolInput = {
  rootNodeId: string;
  clientMutationId?: string | null;
};
export type CreateElementsPoolButton_CreatePoolMutation$variables = {
  input: CreateElementsPoolInput;
};
export type CreateElementsPoolButton_CreatePoolMutationVariables = CreateElementsPoolButton_CreatePoolMutation$variables;
export type CreateElementsPoolButton_CreatePoolMutation$data = {
  readonly Admin: {
    readonly Elementspool: {
      readonly createElementsPoolAdminMutation: {
        readonly node: {
          readonly id: string;
          readonly structureDefinition: {
            readonly extension?: {
              readonly elementsPoolId?: string;
            };
          };
        };
        readonly elementsPool: {
          readonly id: string;
          readonly elements: ReadonlyArray<{
            readonly id: string;
            readonly elementType: ElementTypeV2;
            readonly title: string;
            readonly " $fragmentSpreads": FragmentRefs<"PoolElementForm_ElementV2Fragment">;
          }>;
        };
      } | null;
    };
  };
};
export type CreateElementsPoolButton_CreatePoolMutationResponse = CreateElementsPoolButton_CreatePoolMutation$data;
export type CreateElementsPoolButton_CreatePoolMutation = {
  variables: CreateElementsPoolButton_CreatePoolMutationVariables;
  response: CreateElementsPoolButton_CreatePoolMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elementsPoolId",
      "storageKey": null
    }
  ],
  "type": "RootExtensionImpl",
  "abstractKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "elementType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "File",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateElementsPoolButton_CreatePoolMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElementspoolAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Elementspool",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CreateElementsPoolPayload",
                "kind": "LinkedField",
                "name": "createElementsPoolAdminMutation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "extension",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "RootStructureDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MultipleChoiceElementsPool",
                    "kind": "LinkedField",
                    "name": "elementsPool",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MultipleChoiceElementV2",
                        "kind": "LinkedField",
                        "name": "elements",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "PoolElementForm_ElementV2Fragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateElementsPoolButton_CreatePoolMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElementspoolAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Elementspool",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CreateElementsPoolPayload",
                "kind": "LinkedField",
                "name": "createElementsPoolAdminMutation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "extension",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "RootStructureDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MultipleChoiceElementsPool",
                    "kind": "LinkedField",
                    "name": "elementsPool",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MultipleChoiceElementV2",
                        "kind": "LinkedField",
                        "name": "elements",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "question",
                                    "storageKey": null
                                  },
                                  (v7/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "answeringType",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AnswerOptionV2",
                                    "kind": "LinkedField",
                                    "name": "answerOptions",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "answer",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "isCorrect",
                                        "storageKey": null
                                      },
                                      (v7/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "MultipleChoiceElementV2",
                                "abstractKey": null
                              }
                            ],
                            "type": "ElementV2",
                            "abstractKey": "__isElementV2"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "01493468016f6e93808d749900490a5c",
    "id": null,
    "metadata": {},
    "name": "CreateElementsPoolButton_CreatePoolMutation",
    "operationKind": "mutation",
    "text": "mutation CreateElementsPoolButton_CreatePoolMutation(\n  $input: CreateElementsPoolInput!\n) {\n  Admin {\n    Elementspool {\n      createElementsPoolAdminMutation(input: $input) {\n        node {\n          id\n          structureDefinition {\n            __typename\n            ... on RootStructureDefinition {\n              extension {\n                __typename\n                ... on RootExtensionImpl {\n                  elementsPoolId\n                }\n              }\n            }\n          }\n        }\n        elementsPool {\n          id\n          elements {\n            id\n            elementType\n            title\n            ...PoolElementForm_ElementV2Fragment\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment MultipleChoicePoolElementForm_MultipleChoiceElementV2Fragment on MultipleChoiceElementV2 {\n  id\n  title\n  question\n  image {\n    id\n  }\n  answeringType\n  answerOptions {\n    answer\n    isCorrect\n    image {\n      id\n    }\n  }\n}\n\nfragment PoolElementForm_ElementV2Fragment on ElementV2 {\n  __isElementV2: __typename\n  title\n  elementType\n  ... on MultipleChoiceElementV2 {\n    ...MultipleChoicePoolElementForm_MultipleChoiceElementV2Fragment\n  }\n}\n"
  }
};
})();

(node as any).hash = "e66a4ab7b7b914ff26b155a124ec422c";

export default node;
