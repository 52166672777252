/**
 * @generated SignedSource<<695f40e352b8bde5246a95bc5f0abc5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RewardTreeConfigType = "RewardTree_DoNot" | "RewardTree_IfRepeatedDoNot" | "RewardTree_AfterDateTimeDoNot" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NotAfterDateTimeRewardConfigForm_AfterDateTimeDoNotRewardTreeConfigFragment$data = {
  readonly id: string;
  readonly configType: RewardTreeConfigType;
  readonly dateTime: string;
  readonly " $fragmentType": "NotAfterDateTimeRewardConfigForm_AfterDateTimeDoNotRewardTreeConfigFragment";
};
export type NotAfterDateTimeRewardConfigForm_AfterDateTimeDoNotRewardTreeConfigFragment = NotAfterDateTimeRewardConfigForm_AfterDateTimeDoNotRewardTreeConfigFragment$data;
export type NotAfterDateTimeRewardConfigForm_AfterDateTimeDoNotRewardTreeConfigFragment$key = {
  readonly " $data"?: NotAfterDateTimeRewardConfigForm_AfterDateTimeDoNotRewardTreeConfigFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotAfterDateTimeRewardConfigForm_AfterDateTimeDoNotRewardTreeConfigFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotAfterDateTimeRewardConfigForm_AfterDateTimeDoNotRewardTreeConfigFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "configType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateTime",
      "storageKey": null
    }
  ],
  "type": "AfterDateTimeDoNotRewardTreeConfig",
  "abstractKey": null
};

(node as any).hash = "50de67f8f2a56b6e36e1f6a574d081ed";

export default node;
