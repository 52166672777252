/**
 * @generated SignedSource<<5bc92b08ecd3c8361b8b7c8b8b641aa1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VisibilityTreeConfigType = "VisibilityTree_OnlyFachrichtungen" | "VisibilityTree_OnlyIfTreeStateOrAdmin" | "VisibilityTree_OnlySpecificPermissions" | "VisibilityTree_HideForFachberater" | "VisibilityTree_OnlyBasicSeminar" | "VisibilityTree_OnlyFachberater" | "VisibilityTree_AfterDateTime" | "VisibilityTree_Hide" | "VisibilityTree_NotAfterDateTime" | "VisibilityTree_OnlyAdmins" | "VisibilityTree_OnlyIfTreeState" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$data = {
  readonly id: string;
  readonly dateTime: string;
  readonly configType: VisibilityTreeConfigType;
  readonly " $fragmentType": "AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment";
};
export type AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment = AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$data;
export type AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$key = {
  readonly " $data"?: AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "configType",
      "storageKey": null
    }
  ],
  "type": "NotAfterDateTimeVisibilityTreeConfig",
  "abstractKey": null
};

(node as any).hash = "e7710fd99160c0456e81abd1cac660de";

export default node;
