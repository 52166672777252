/**
 * @generated SignedSource<<ce8b12baf3b7cf0619ea06bc9177671b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ElementTypeV2 = "randomMultipleChoice" | "file" | "multipleChoice" | "podcast" | "text" | "video" | "order" | "clozeText" | "%future added value";
export type MultipleChoiceElementsPool_Query$variables = {
  elementsPoolId: string;
};
export type MultipleChoiceElementsPool_QueryVariables = MultipleChoiceElementsPool_Query$variables;
export type MultipleChoiceElementsPool_Query$data = {
  readonly Admin: {
    readonly Elementspool: {
      readonly MultipleChoiceElementsPool: {
        readonly id: string;
        readonly elements: ReadonlyArray<{
          readonly id: string;
          readonly elementType: ElementTypeV2;
          readonly title: string;
          readonly question: string;
          readonly " $fragmentSpreads": FragmentRefs<"PoolElementForm_ElementV2Fragment">;
        }>;
      };
    };
  };
};
export type MultipleChoiceElementsPool_QueryResponse = MultipleChoiceElementsPool_Query$data;
export type MultipleChoiceElementsPool_Query = {
  variables: MultipleChoiceElementsPool_QueryVariables;
  response: MultipleChoiceElementsPool_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "elementsPoolId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "elementsPoolId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "elementType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "question",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "File",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MultipleChoiceElementsPool_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElementspoolAdminSchema",
            "kind": "LinkedField",
            "name": "Elementspool",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "MultipleChoiceElementsPool",
                "kind": "LinkedField",
                "name": "MultipleChoiceElementsPool",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MultipleChoiceElementV2",
                    "kind": "LinkedField",
                    "name": "elements",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "PoolElementForm_ElementV2Fragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MultipleChoiceElementsPool_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElementspoolAdminSchema",
            "kind": "LinkedField",
            "name": "Elementspool",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "MultipleChoiceElementsPool",
                "kind": "LinkedField",
                "name": "MultipleChoiceElementsPool",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MultipleChoiceElementV2",
                    "kind": "LinkedField",
                    "name": "elements",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "answeringType",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AnswerOptionV2",
                                "kind": "LinkedField",
                                "name": "answerOptions",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "answer",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isCorrect",
                                    "storageKey": null
                                  },
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "MultipleChoiceElementV2",
                            "abstractKey": null
                          }
                        ],
                        "type": "ElementV2",
                        "abstractKey": "__isElementV2"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "082fa2a8581f786cd72a436b7113efda",
    "id": null,
    "metadata": {},
    "name": "MultipleChoiceElementsPool_Query",
    "operationKind": "query",
    "text": "query MultipleChoiceElementsPool_Query(\n  $elementsPoolId: ID!\n) {\n  Admin {\n    Elementspool {\n      MultipleChoiceElementsPool(id: $elementsPoolId) {\n        id\n        elements {\n          id\n          elementType\n          title\n          question\n          ...PoolElementForm_ElementV2Fragment\n        }\n      }\n    }\n  }\n}\n\nfragment MultipleChoicePoolElementForm_MultipleChoiceElementV2Fragment on MultipleChoiceElementV2 {\n  id\n  title\n  question\n  image {\n    id\n  }\n  answeringType\n  answerOptions {\n    answer\n    isCorrect\n    image {\n      id\n    }\n  }\n}\n\nfragment PoolElementForm_ElementV2Fragment on ElementV2 {\n  __isElementV2: __typename\n  title\n  elementType\n  ... on MultipleChoiceElementV2 {\n    ...MultipleChoicePoolElementForm_MultipleChoiceElementV2Fragment\n  }\n}\n"
  }
};
})();

(node as any).hash = "f529987e1cbc89a2a5f2db330c976f4f";

export default node;
