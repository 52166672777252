/**
 * @generated SignedSource<<470442b8d1873f10909357894f5796b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StartTreeConfigType = "StartTree_CanNot" | "StartTree_CMECertifiedNeedsSpecificGlobalUserStatus" | "StartTree_SpecificUserRolesCan" | "StartTree_SpecificUserStatusesCan" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProjectExtendStartTreeConfigEditDialogs_StartTreeConfigFragment$data = {
  readonly configType: StartTreeConfigType;
  readonly " $fragmentSpreads": FragmentRefs<"SpecificUserStatusesCanStartTreeConfigForm_StartTreeConfigFragment" | "SpecificUserRolesCanStartTreeConfigForm_StartTreeConfigFragment">;
  readonly " $fragmentType": "ProjectExtendStartTreeConfigEditDialogs_StartTreeConfigFragment";
};
export type ProjectExtendStartTreeConfigEditDialogs_StartTreeConfigFragment = ProjectExtendStartTreeConfigEditDialogs_StartTreeConfigFragment$data;
export type ProjectExtendStartTreeConfigEditDialogs_StartTreeConfigFragment$key = {
  readonly " $data"?: ProjectExtendStartTreeConfigEditDialogs_StartTreeConfigFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectExtendStartTreeConfigEditDialogs_StartTreeConfigFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectExtendStartTreeConfigEditDialogs_StartTreeConfigFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "configType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SpecificUserStatusesCanStartTreeConfigForm_StartTreeConfigFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SpecificUserRolesCanStartTreeConfigForm_StartTreeConfigFragment"
    }
  ],
  "type": "StartTreeConfig",
  "abstractKey": "__isStartTreeConfig"
};

(node as any).hash = "2df6dff924ff5d28479c5647877613a6";

export default node;
