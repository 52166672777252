/**
 * @generated SignedSource<<73ee68bbc9f3bd21ee67703f5e787fde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RecipientType = "user" | "admin" | "%future added value";
export type RewardResultKind = "PointsRewardAlreadyRewardedResult" | "PointsRewardWasPossibleResult" | "PointsRewardWasNotPossibleResult" | "EmailRewardAlreadyRewardedResult" | "EmailRewardWasPossibleResult" | "EmailRewardWasNotPossibleResult" | "CertificateRewardAlreadyRewardedResult" | "CertificateRewardWasPossibleResult" | "CertificateRewardWasNotPossibleResult" | "MakeFachberaterRewardAlreadyRewardedResult" | "FachberaterWasAwarded" | "MakeFachberaterRewardWasNotPossibleResult" | "PacPointsRewardAlreadyRewardedResult" | "PacPointsRewardWasPossibleResult" | "PacPointsRewardWasNotPossibleResult" | "PharmaPointsRewardAlreadyRewardedResult" | "PharmaPointsRewardWasPossibleResult" | "PharmaPointsRewardWasNotPossibleResult" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProjectRewardsColumn_ContentSubmissionFragment$data = {
  readonly definition: {
    readonly rewardResults?: ReadonlyArray<{
      readonly kind: RewardResultKind;
      readonly pointsPoolName?: string;
      readonly amount?: number;
      readonly certificateName?: string;
      readonly recipientType?: RecipientType;
    }>;
  };
  readonly " $fragmentType": "ProjectRewardsColumn_ContentSubmissionFragment";
};
export type ProjectRewardsColumn_ContentSubmissionFragment = ProjectRewardsColumn_ContentSubmissionFragment$data;
export type ProjectRewardsColumn_ContentSubmissionFragment$key = {
  readonly " $data"?: ProjectRewardsColumn_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectRewardsColumn_ContentSubmissionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectRewardsColumn_ContentSubmissionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "rewardResults",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "pointsPoolName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "amount",
                      "storageKey": null
                    }
                  ],
                  "type": "PointsRewardWasPossibleResult",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "certificateName",
                      "storageKey": null
                    }
                  ],
                  "type": "CertificateRewardWasPossibleResult",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "recipientType",
                      "storageKey": null
                    }
                  ],
                  "type": "EmailRewardWasPossibleResult",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "PassedELearningContentSubmissionDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};

(node as any).hash = "7573a1e42db3272980bdad018a927e39";

export default node;
