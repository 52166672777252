/**
 * @generated SignedSource<<19b345e117c29094890dba2f4f2b9fe2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RewardKind = "Points" | "Email" | "Certificate" | "MakeFachberater" | "PacPoints" | "PharmaPoints" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CreateFachberaterRewardButton_TreeNodeFragment$data = {
  readonly id: string;
  readonly typeDefinition: {
    readonly rewards?: ReadonlyArray<{
      readonly id: string;
      readonly kind: RewardKind;
    }>;
  };
  readonly " $fragmentType": "CreateFachberaterRewardButton_TreeNodeFragment";
};
export type CreateFachberaterRewardButton_TreeNodeFragment = CreateFachberaterRewardButton_TreeNodeFragment$data;
export type CreateFachberaterRewardButton_TreeNodeFragment$key = {
  readonly " $data"?: CreateFachberaterRewardButton_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateFachberaterRewardButton_TreeNodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateFachberaterRewardButton_TreeNodeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "rewards",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ELearningContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};
})();

(node as any).hash = "5853697f821daddb3046c63b3c28a7b6";

export default node;
