/**
 * @generated SignedSource<<cd062b48308b9c4a6d1e877b4bd97970>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PassContentConfigType = "PassContent_Always" | "PassContent_OnPercentage" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OnPercentagePassContentConfigForm_OnPercentagePassContentConfigFragment$data = {
  readonly id: string;
  readonly configType: PassContentConfigType;
  readonly requiredPercentage: number;
  readonly " $fragmentType": "OnPercentagePassContentConfigForm_OnPercentagePassContentConfigFragment";
};
export type OnPercentagePassContentConfigForm_OnPercentagePassContentConfigFragment = OnPercentagePassContentConfigForm_OnPercentagePassContentConfigFragment$data;
export type OnPercentagePassContentConfigForm_OnPercentagePassContentConfigFragment$key = {
  readonly " $data"?: OnPercentagePassContentConfigForm_OnPercentagePassContentConfigFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OnPercentagePassContentConfigForm_OnPercentagePassContentConfigFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OnPercentagePassContentConfigForm_OnPercentagePassContentConfigFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "configType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiredPercentage",
      "storageKey": null
    }
  ],
  "type": "OnPercentagePassContentConfig",
  "abstractKey": null
};

(node as any).hash = "1c575a2d99e6f08ecc35f83841d5fa90";

export default node;
