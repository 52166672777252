/**
 * @generated SignedSource<<090cbd1849796c00022bc68c13864b2c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VisibilityTreeConfigType = "VisibilityTree_OnlyFachrichtungen" | "VisibilityTree_OnlyIfTreeStateOrAdmin" | "VisibilityTree_OnlySpecificPermissions" | "VisibilityTree_HideForFachberater" | "VisibilityTree_OnlyBasicSeminar" | "VisibilityTree_OnlyFachberater" | "VisibilityTree_AfterDateTime" | "VisibilityTree_Hide" | "VisibilityTree_NotAfterDateTime" | "VisibilityTree_OnlyAdmins" | "VisibilityTree_OnlyIfTreeState" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$data = {
  readonly id: string;
  readonly dateTime: string;
  readonly configType: VisibilityTreeConfigType;
  readonly " $fragmentType": "NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment";
};
export type NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment = NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$data;
export type NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$key = {
  readonly " $data"?: NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "configType",
      "storageKey": null
    }
  ],
  "type": "NotAfterDateTimeVisibilityTreeConfig",
  "abstractKey": null
};

(node as any).hash = "d944e48e07cc84cd6d2cca97c4d5efb2";

export default node;
