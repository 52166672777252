/**
 * @generated SignedSource<<cc83f59e4cfef3873167de843c5dd9a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VisibilityTreeConfigType = "VisibilityTree_OnlyFachrichtungen" | "VisibilityTree_OnlyIfTreeStateOrAdmin" | "VisibilityTree_OnlySpecificPermissions" | "VisibilityTree_HideForFachberater" | "VisibilityTree_OnlyBasicSeminar" | "VisibilityTree_OnlyFachberater" | "VisibilityTree_AfterDateTime" | "VisibilityTree_Hide" | "VisibilityTree_NotAfterDateTime" | "VisibilityTree_OnlyAdmins" | "VisibilityTree_OnlyIfTreeState" | "%future added value";
export type AddOnlyIfTreeStateOrAdminVisibilityTreeConfigInput = {
  rootNodeId: string;
  clientMutationId?: string | null;
};
export type OnlyIfTreeStateOrAdminVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation$variables = {
  input: AddOnlyIfTreeStateOrAdminVisibilityTreeConfigInput;
};
export type OnlyIfTreeStateOrAdminVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutationVariables = OnlyIfTreeStateOrAdminVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation$variables;
export type OnlyIfTreeStateOrAdminVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly AddOnlyIfTreeStateOrAdminVisibilityTreeConfig: {
        readonly rootNode: {
          readonly id: string;
          readonly structureDefinition: {
            readonly visibilityConfigs?: ReadonlyArray<{
              readonly id?: string;
              readonly configType?: VisibilityTreeConfigType;
            }>;
          };
        };
      } | null;
    };
  };
};
export type OnlyIfTreeStateOrAdminVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutationResponse = OnlyIfTreeStateOrAdminVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation$data;
export type OnlyIfTreeStateOrAdminVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation = {
  variables: OnlyIfTreeStateOrAdminVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutationVariables;
  response: OnlyIfTreeStateOrAdminVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "configType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OnlyIfTreeStateOrAdminVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddOnlyIfTreeStateOrAdminVisibilityTreeConfigPayload",
                "kind": "LinkedField",
                "name": "AddOnlyIfTreeStateOrAdminVisibilityTreeConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "rootNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "visibilityConfigs",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v2/*: any*/),
                                      (v3/*: any*/)
                                    ],
                                    "type": "OnlyIfTreeStateOrAdminVisibilityTreeConfig",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "RootStructureDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OnlyIfTreeStateOrAdminVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddOnlyIfTreeStateOrAdminVisibilityTreeConfigPayload",
                "kind": "LinkedField",
                "name": "AddOnlyIfTreeStateOrAdminVisibilityTreeConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "rootNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "visibilityConfigs",
                                "plural": true,
                                "selections": [
                                  (v4/*: any*/),
                                  (v2/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v3/*: any*/)
                                    ],
                                    "type": "OnlyIfTreeStateOrAdminVisibilityTreeConfig",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "RootStructureDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "171f78e2a67b35aeb8bf7fb78ae3c39d",
    "id": null,
    "metadata": {},
    "name": "OnlyIfTreeStateOrAdminVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation",
    "operationKind": "mutation",
    "text": "mutation OnlyIfTreeStateOrAdminVisibilityConfigForm_AddInfectopharmOnlyAdminsVisibilityConfigMutation(\n  $input: AddOnlyIfTreeStateOrAdminVisibilityTreeConfigInput!\n) {\n  Admin {\n    Tree {\n      AddOnlyIfTreeStateOrAdminVisibilityTreeConfig(input: $input) {\n        rootNode {\n          id\n          structureDefinition {\n            __typename\n            ... on RootStructureDefinition {\n              visibilityConfigs {\n                __typename\n                ... on OnlyIfTreeStateOrAdminVisibilityTreeConfig {\n                  id\n                  configType\n                }\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "986821a4128b0cec3406b1b81fb1e6ca";

export default node;
