/**
 * @generated SignedSource<<6748f2f991cc400354eb633d3d1bfe71>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RestartIfFailedContentConfigType = "RestartIfFailedContent_BlockTemporarily_WhenRewarded" | "RestartIfFailedContent_BlockPermanently_WhenRewarded" | "RestartIfFailedContent_BlockPermanently" | "RestartIfFailedContent_BlockTemporarily" | "RestartIfFailedContent_CanNot" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProjectExtendRestartIfFailedContentConfigEditor_RestartIfFailedContentConfigFragment$data = {
  readonly id: string;
  readonly configType: RestartIfFailedContentConfigType;
  readonly " $fragmentType": "ProjectExtendRestartIfFailedContentConfigEditor_RestartIfFailedContentConfigFragment";
};
export type ProjectExtendRestartIfFailedContentConfigEditor_RestartIfFailedContentConfigFragment = ProjectExtendRestartIfFailedContentConfigEditor_RestartIfFailedContentConfigFragment$data;
export type ProjectExtendRestartIfFailedContentConfigEditor_RestartIfFailedContentConfigFragment$key = {
  readonly " $data"?: ProjectExtendRestartIfFailedContentConfigEditor_RestartIfFailedContentConfigFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectExtendRestartIfFailedContentConfigEditor_RestartIfFailedContentConfigFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectExtendRestartIfFailedContentConfigEditor_RestartIfFailedContentConfigFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "configType",
      "storageKey": null
    }
  ],
  "type": "RestartIfFailedContentConfig",
  "abstractKey": "__isRestartIfFailedContentConfig"
};

(node as any).hash = "6b06d4852709a8b92c1e45e92b31bdc1";

export default node;
