/**
 * @generated SignedSource<<28a8c936ae76b41e9b4987d86c65ebb4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VisibilityTreeConfigType = "VisibilityTree_OnlyFachrichtungen" | "VisibilityTree_OnlyIfTreeStateOrAdmin" | "VisibilityTree_OnlySpecificPermissions" | "VisibilityTree_HideForFachberater" | "VisibilityTree_OnlyBasicSeminar" | "VisibilityTree_OnlyFachberater" | "VisibilityTree_AfterDateTime" | "VisibilityTree_Hide" | "VisibilityTree_NotAfterDateTime" | "VisibilityTree_OnlyAdmins" | "VisibilityTree_OnlyIfTreeState" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type VisibilityTreeConfigEditor_TreeNodeFragment$data = {
  readonly id: string;
  readonly structureDefinition: {
    readonly visibilityConfigs?: ReadonlyArray<{
      readonly id: string;
      readonly configType: VisibilityTreeConfigType;
      readonly dateTime?: string;
      readonly " $fragmentSpreads": FragmentRefs<"NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment" | "AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment" | "ProjectExtendVisibilityConfigEditDialogs_VisibilityTreeConfigFragment">;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"NotAfterDateTimeVisibilityConfigForm_TreeNodeFragment" | "AfterDateTimeVisibilityConfigForm_TreeNodeFragment" | "ProjectExtendVisibilityConfigEditDialogs_TreeNodeFragment">;
  readonly " $fragmentType": "VisibilityTreeConfigEditor_TreeNodeFragment";
};
export type VisibilityTreeConfigEditor_TreeNodeFragment = VisibilityTreeConfigEditor_TreeNodeFragment$data;
export type VisibilityTreeConfigEditor_TreeNodeFragment$key = {
  readonly " $data"?: VisibilityTreeConfigEditor_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"VisibilityTreeConfigEditor_TreeNodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dateTime",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VisibilityTreeConfigEditor_TreeNodeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "visibilityConfigs",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "configType",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v1/*: any*/),
                  "type": "NotAfterDateTimeVisibilityTreeConfig",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v1/*: any*/),
                  "type": "AfterDateTimeVisibilityTreeConfig",
                  "abstractKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ProjectExtendVisibilityConfigEditDialogs_VisibilityTreeConfigFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotAfterDateTimeVisibilityConfigForm_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AfterDateTimeVisibilityConfigForm_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectExtendVisibilityConfigEditDialogs_TreeNodeFragment"
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};
})();

(node as any).hash = "b5610726adb2ca9f4d0f9f1b631d1771";

export default node;
