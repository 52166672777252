/**
 * @generated SignedSource<<e95456af047c29a94b60be68dea56782>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VisibilityTreeConfigType = "VisibilityTree_OnlyFachrichtungen" | "VisibilityTree_OnlyIfTreeStateOrAdmin" | "VisibilityTree_OnlySpecificPermissions" | "VisibilityTree_HideForFachberater" | "VisibilityTree_OnlyBasicSeminar" | "VisibilityTree_OnlyFachberater" | "VisibilityTree_AfterDateTime" | "VisibilityTree_Hide" | "VisibilityTree_NotAfterDateTime" | "VisibilityTree_OnlyAdmins" | "VisibilityTree_OnlyIfTreeState" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProjectExtendVisibilityConfigEditDialogs_TreeNodeFragment$data = {
  readonly id: string;
  readonly structureDefinition: {
    readonly visibilityConfigs?: ReadonlyArray<{
      readonly id?: string;
      readonly configType?: VisibilityTreeConfigType;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"OnlySpecializationVisibilityConfigForm_TreeNodeFragment" | "OnlySpecificPermissionsVisibilityConfigForm_TreeNodeFragment">;
  readonly " $fragmentType": "ProjectExtendVisibilityConfigEditDialogs_TreeNodeFragment";
};
export type ProjectExtendVisibilityConfigEditDialogs_TreeNodeFragment = ProjectExtendVisibilityConfigEditDialogs_TreeNodeFragment$data;
export type ProjectExtendVisibilityConfigEditDialogs_TreeNodeFragment$key = {
  readonly " $data"?: ProjectExtendVisibilityConfigEditDialogs_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectExtendVisibilityConfigEditDialogs_TreeNodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectExtendVisibilityConfigEditDialogs_TreeNodeFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OnlySpecializationVisibilityConfigForm_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OnlySpecificPermissionsVisibilityConfigForm_TreeNodeFragment"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "visibilityConfigs",
              "plural": true,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "configType",
                      "storageKey": null
                    }
                  ],
                  "type": "OnlyIfTreeStateOrAdminVisibilityTreeConfig",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};
})();

(node as any).hash = "220d09d2afc150d7be41f884722a3105";

export default node;
