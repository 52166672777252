/**
 * @generated SignedSource<<e323ec4f892ec03358d7d6d890c016ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VisibilityTreeConfigType = "VisibilityTree_OnlyFachrichtungen" | "VisibilityTree_OnlyIfTreeStateOrAdmin" | "VisibilityTree_OnlySpecificPermissions" | "VisibilityTree_HideForFachberater" | "VisibilityTree_OnlyBasicSeminar" | "VisibilityTree_OnlyFachberater" | "VisibilityTree_AfterDateTime" | "VisibilityTree_Hide" | "VisibilityTree_NotAfterDateTime" | "VisibilityTree_OnlyAdmins" | "VisibilityTree_OnlyIfTreeState" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OnlySpecificPermissionsVisibilityConfigForm_VisibilityTreeConfigFragment$data = {
  readonly id?: string;
  readonly configType?: VisibilityTreeConfigType;
  readonly dateTime?: string;
  readonly " $fragmentType": "OnlySpecificPermissionsVisibilityConfigForm_VisibilityTreeConfigFragment";
};
export type OnlySpecificPermissionsVisibilityConfigForm_VisibilityTreeConfigFragment = OnlySpecificPermissionsVisibilityConfigForm_VisibilityTreeConfigFragment$data;
export type OnlySpecificPermissionsVisibilityConfigForm_VisibilityTreeConfigFragment$key = {
  readonly " $data"?: OnlySpecificPermissionsVisibilityConfigForm_VisibilityTreeConfigFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OnlySpecificPermissionsVisibilityConfigForm_VisibilityTreeConfigFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OnlySpecificPermissionsVisibilityConfigForm_VisibilityTreeConfigFragment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "configType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dateTime",
          "storageKey": null
        }
      ],
      "type": "OnlySpecificPermissionsVisibilityTreeConfig",
      "abstractKey": null
    }
  ],
  "type": "VisibilityTreeConfig",
  "abstractKey": "__isVisibilityTreeConfig"
};

(node as any).hash = "931fe66666aef994246ef615d185b9e7";

export default node;
