/**
 * @generated SignedSource<<b0ea44ef1813adb9a2dd49be62822758>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RewardKind = "Points" | "Email" | "Certificate" | "MakeFachberater" | "PacPoints" | "PharmaPoints" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RewardForm_RewardConfigurationV2Fragment$data = {
  readonly id: string;
  readonly kind: RewardKind;
  readonly " $fragmentSpreads": FragmentRefs<"EmailRewardConfigurationForm_EmailRewardConfigurationV2Fragment" | "EditAwardPacPointsConfigurationForm_AwardPacPointsFragment" | "EditAwardPharmaPointsConfigurationForm_AwardPharmaPointsFragment" | "CertificatesRewardConfigurationForm_CertificateRewardConfigurationV2Fragment" | "EditFachberaterRewardConfigurationForm_MakeFachberaterRewardConfigurationFragment">;
  readonly " $fragmentType": "RewardForm_RewardConfigurationV2Fragment";
};
export type RewardForm_RewardConfigurationV2Fragment = RewardForm_RewardConfigurationV2Fragment$data;
export type RewardForm_RewardConfigurationV2Fragment$key = {
  readonly " $data"?: RewardForm_RewardConfigurationV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RewardForm_RewardConfigurationV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RewardForm_RewardConfigurationV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EmailRewardConfigurationForm_EmailRewardConfigurationV2Fragment"
        }
      ],
      "type": "EmailRewardConfiguration",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EditAwardPacPointsConfigurationForm_AwardPacPointsFragment"
        }
      ],
      "type": "AwardPacPointsRewardConfiguration",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EditAwardPharmaPointsConfigurationForm_AwardPharmaPointsFragment"
        }
      ],
      "type": "AwardPharmaPointsRewardConfiguration",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CertificatesRewardConfigurationForm_CertificateRewardConfigurationV2Fragment"
        }
      ],
      "type": "CertificateRewardConfiguration",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EditFachberaterRewardConfigurationForm_MakeFachberaterRewardConfigurationFragment"
        }
      ],
      "type": "MakeFachberaterRewardConfiguration",
      "abstractKey": null
    }
  ],
  "type": "Reward",
  "abstractKey": "__isReward"
};

(node as any).hash = "071332216727f3c7a780d7f2014e692b";

export default node;
