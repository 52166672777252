/**
 * @generated SignedSource<<79d098e2467f02c0890ddc6054560275>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ElementTypeV2 = "randomMultipleChoice" | "file" | "multipleChoice" | "podcast" | "text" | "video" | "order" | "clozeText" | "%future added value";
export type AddElementsPoolElementInput = {
  title: string;
  question: string;
  answeringType: string;
  answerOptions: ReadonlyArray<AnswerOptionV2Input>;
  poolId: string;
  imageId?: string | null;
  clientMutationId?: string | null;
};
export type AnswerOptionV2Input = {
  answer: string;
  isCorrect: boolean;
  imageRef?: string | null;
};
export type MultipleChoiceElementsPool_CreateMultipleChoiceElementV2Mutation$variables = {
  input: AddElementsPoolElementInput;
};
export type MultipleChoiceElementsPool_CreateMultipleChoiceElementV2MutationVariables = MultipleChoiceElementsPool_CreateMultipleChoiceElementV2Mutation$variables;
export type MultipleChoiceElementsPool_CreateMultipleChoiceElementV2Mutation$data = {
  readonly Admin: {
    readonly Elementspool: {
      readonly addElementsPoolElement: {
        readonly pool: {
          readonly id: string;
          readonly elements: ReadonlyArray<{
            readonly id: string;
            readonly elementType: ElementTypeV2;
            readonly title: string;
            readonly question: string;
            readonly " $fragmentSpreads": FragmentRefs<"PoolElementForm_ElementV2Fragment">;
          }>;
        };
      } | null;
    };
  };
};
export type MultipleChoiceElementsPool_CreateMultipleChoiceElementV2MutationResponse = MultipleChoiceElementsPool_CreateMultipleChoiceElementV2Mutation$data;
export type MultipleChoiceElementsPool_CreateMultipleChoiceElementV2Mutation = {
  variables: MultipleChoiceElementsPool_CreateMultipleChoiceElementV2MutationVariables;
  response: MultipleChoiceElementsPool_CreateMultipleChoiceElementV2Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "elementType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "question",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "File",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MultipleChoiceElementsPool_CreateMultipleChoiceElementV2Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElementspoolAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Elementspool",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddElementsPoolElementPayload",
                "kind": "LinkedField",
                "name": "addElementsPoolElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MultipleChoiceElementsPool",
                    "kind": "LinkedField",
                    "name": "pool",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MultipleChoiceElementV2",
                        "kind": "LinkedField",
                        "name": "elements",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "PoolElementForm_ElementV2Fragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MultipleChoiceElementsPool_CreateMultipleChoiceElementV2Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElementspoolAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Elementspool",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddElementsPoolElementPayload",
                "kind": "LinkedField",
                "name": "addElementsPoolElement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MultipleChoiceElementsPool",
                    "kind": "LinkedField",
                    "name": "pool",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MultipleChoiceElementV2",
                        "kind": "LinkedField",
                        "name": "elements",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "answeringType",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AnswerOptionV2",
                                    "kind": "LinkedField",
                                    "name": "answerOptions",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "answer",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "isCorrect",
                                        "storageKey": null
                                      },
                                      (v6/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "MultipleChoiceElementV2",
                                "abstractKey": null
                              }
                            ],
                            "type": "ElementV2",
                            "abstractKey": "__isElementV2"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "edb53a9cc7893501c577ae4ef79df46b",
    "id": null,
    "metadata": {},
    "name": "MultipleChoiceElementsPool_CreateMultipleChoiceElementV2Mutation",
    "operationKind": "mutation",
    "text": "mutation MultipleChoiceElementsPool_CreateMultipleChoiceElementV2Mutation(\n  $input: AddElementsPoolElementInput!\n) {\n  Admin {\n    Elementspool {\n      addElementsPoolElement(input: $input) {\n        pool {\n          id\n          elements {\n            id\n            elementType\n            title\n            question\n            ...PoolElementForm_ElementV2Fragment\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment MultipleChoicePoolElementForm_MultipleChoiceElementV2Fragment on MultipleChoiceElementV2 {\n  id\n  title\n  question\n  image {\n    id\n  }\n  answeringType\n  answerOptions {\n    answer\n    isCorrect\n    image {\n      id\n    }\n  }\n}\n\nfragment PoolElementForm_ElementV2Fragment on ElementV2 {\n  __isElementV2: __typename\n  title\n  elementType\n  ... on MultipleChoiceElementV2 {\n    ...MultipleChoicePoolElementForm_MultipleChoiceElementV2Fragment\n  }\n}\n"
  }
};
})();

(node as any).hash = "6851395dd4d4f244f285663394118540";

export default node;
