/**
 * @generated SignedSource<<fee8b62351afebb5250c2e0eb2b09c79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DisplayType = "notDisplayed" | "displayedToSpecificUsers" | "displayedToEveryone" | "%future added value";
export type RewardKind = "Points" | "Email" | "Certificate" | "MakeFachberater" | "PacPoints" | "PharmaPoints" | "%future added value";
export type StructureType = "root" | "child" | "%future added value";
export type TypeDefinitionType = "content" | "branch" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NodeConfigurationBadges_TreeNodeFragment$data = {
  readonly id: string;
  readonly structureDefinition: {
    readonly definitionType: StructureType;
    readonly advertisementConfiguration?: {
      readonly isFeatured: boolean;
    } | null;
    readonly extension?: {
      readonly displayType?: DisplayType;
      readonly configConsequences?: {
        readonly isRewardedTo: string | null;
      };
    };
  };
  readonly typeDefinition: {
    readonly definitionType: TypeDefinitionType;
    readonly rewards?: ReadonlyArray<{
      readonly kind: RewardKind;
    }>;
  };
  readonly versioning: {
    readonly draftVersion: number;
    readonly releaseVersion: number;
  };
  readonly " $fragmentType": "NodeConfigurationBadges_TreeNodeFragment";
};
export type NodeConfigurationBadges_TreeNodeFragment = NodeConfigurationBadges_TreeNodeFragment$data;
export type NodeConfigurationBadges_TreeNodeFragment$key = {
  readonly " $data"?: NodeConfigurationBadges_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NodeConfigurationBadges_TreeNodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "definitionType",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NodeConfigurationBadges_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AdvertisementConfiguration",
              "kind": "LinkedField",
              "name": "advertisementConfiguration",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isFeatured",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "extension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "displayType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TreeConfigConsequences",
                      "kind": "LinkedField",
                      "name": "configConsequences",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isRewardedTo",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "RootExtensionImpl",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "rewards",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ContentTypeDefinition",
          "abstractKey": "__isContentTypeDefinition"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Versioning",
      "kind": "LinkedField",
      "name": "versioning",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "draftVersion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "releaseVersion",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};
})();

(node as any).hash = "33cd9c10dd6d650af8bab8777cd80165";

export default node;
