/**
 * @generated SignedSource<<0bc5abe382524fedaf401542a09d56b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RestartIfFailedContentConfigType = "RestartIfFailedContent_BlockTemporarily_WhenRewarded" | "RestartIfFailedContent_BlockPermanently_WhenRewarded" | "RestartIfFailedContent_BlockPermanently" | "RestartIfFailedContent_BlockTemporarily" | "RestartIfFailedContent_CanNot" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProjectExtendRestartIfFailedContentConfigEditor_TreeNodeFragment$data = {
  readonly id: string;
  readonly typeDefinition: {
    readonly restartIfFailedConfigs?: ReadonlyArray<{
      readonly id: string;
      readonly configType: RestartIfFailedContentConfigType;
    }>;
  };
  readonly " $fragmentType": "ProjectExtendRestartIfFailedContentConfigEditor_TreeNodeFragment";
};
export type ProjectExtendRestartIfFailedContentConfigEditor_TreeNodeFragment = ProjectExtendRestartIfFailedContentConfigEditor_TreeNodeFragment$data;
export type ProjectExtendRestartIfFailedContentConfigEditor_TreeNodeFragment$key = {
  readonly " $data"?: ProjectExtendRestartIfFailedContentConfigEditor_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectExtendRestartIfFailedContentConfigEditor_TreeNodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectExtendRestartIfFailedContentConfigEditor_TreeNodeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "restartIfFailedConfigs",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "configType",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ContentTypeDefinition",
          "abstractKey": "__isContentTypeDefinition"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};
})();

(node as any).hash = "44288e35fe570c9eb545a386195928a3";

export default node;
