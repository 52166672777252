/**
 * @generated SignedSource<<2dd22065156fa7809bc01e32479ac9fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RecipientType = "user" | "admin" | "%future added value";
export type RewardKind = "Points" | "Email" | "Certificate" | "MakeFachberater" | "PacPoints" | "PharmaPoints" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RewardEditor_TreeNodeFragment$data = {
  readonly id: string;
  readonly typeDefinition: {
    readonly rewards?: ReadonlyArray<{
      readonly id: string;
      readonly kind: RewardKind;
      readonly recipientType?: RecipientType;
      readonly subject?: string;
      readonly amount?: number;
      readonly pointPool?: {
        readonly name: string;
      } | null;
      readonly certificateTemplate?: {
        readonly name: string;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"RewardForm_RewardConfigurationV2Fragment">;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"RewardForm_TreeNodeFragment" | "CreateCertificatesRewardButton_TreeNodeFragment" | "CreateEmailRewardButton_TreeNodeFragment" | "CreateFachberaterRewardButton_TreeNodeFragment" | "CreateAwardPacPointsRewardButton_TreeNodeFragment" | "CreateAwardPharmaPointsRewardButton_TreeNodeFragment">;
  readonly " $fragmentType": "RewardEditor_TreeNodeFragment";
};
export type RewardEditor_TreeNodeFragment = RewardEditor_TreeNodeFragment$data;
export type RewardEditor_TreeNodeFragment$key = {
  readonly " $data"?: RewardEditor_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RewardEditor_TreeNodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CertificateTemplate",
    "kind": "LinkedField",
    "name": "certificateTemplate",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  }
],
v4 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RewardEditor_TreeNodeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "rewards",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "recipientType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "subject",
                      "storageKey": null
                    }
                  ],
                  "type": "EmailRewardConfiguration",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PointPool",
                      "kind": "LinkedField",
                      "name": "pointPool",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "type": "PointsRewardConfiguration",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v3/*: any*/),
                  "type": "CertificateRewardConfiguration",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v3/*: any*/),
                  "type": "MakeFachberaterRewardConfiguration",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v4/*: any*/),
                  "type": "AwardPacPointsRewardConfiguration",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v4/*: any*/),
                  "type": "AwardPharmaPointsRewardConfiguration",
                  "abstractKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RewardForm_RewardConfigurationV2Fragment"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ContentTypeDefinition",
          "abstractKey": "__isContentTypeDefinition"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RewardForm_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateCertificatesRewardButton_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateEmailRewardButton_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateFachberaterRewardButton_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateAwardPacPointsRewardButton_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateAwardPharmaPointsRewardButton_TreeNodeFragment"
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};
})();

(node as any).hash = "ad2fe30b47d4198a3a8e70e188010233";

export default node;
